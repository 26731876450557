<template>
  <v-app>
    <Header title="Kennwort vergessen" backLink="/" />
    <v-main>
      <v-card class="mx-auto mt-12" max-width="400" elevation="4">
        <v-form ref="formKennwortVergessen" @submit.prevent="onSubmit(email, reply_email);">
          <v-card-text>
            <v-text-field
              v-model="email"
              id="email"
              label="E-Mail"
              name="email"
              prepend-icon="email"
              type="email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-text-field
              v-model="reply_email"
              id="reply_email"
              label="E-Mail wiederholen"
              name="reply_email"
              prepend-icon="email"
              type="email"
              :rules="[rules.required, rules.email, rules.same_email]"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text type="submit">Abschicken</v-btn>
             <v-btn text color="orange" class="overline" href="/">zurück</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-main>
    <Footer />
    <AlertDialog />
  </v-app>
</template>

<script>

import { KENNWORT_VERGESSEN  } from "@/store/actions.type";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AlertDialog from "@/components/AlertDialog.vue";


export default {
  name: "KennwortVergessen",
  components: {
    Header,
    Footer,
    AlertDialog
  },  
  data() {
    return {
      dialog: false,
      email: null,
      reply_email: null,
      rules: {
          required: value => !!value || 'E-Mail muss gefüllt sein!',
          same_email: value => value == this.email || 'E-Mail Adressen stimmen nicht überein!',

          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Ungültige E-Mail Adresse!'
          },
        },
    };
  },
  methods: {

    onSubmit(email, reply_email) {
    if (this.$refs.formKennwortVergessen.validate()) {
          this.$store
        .dispatch(KENNWORT_VERGESSEN, { email, reply_email })
     }
    }
  }
};
</script>